const isUrl = (str : string) => {
    // eslint-disable-next-line
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
}

function convertToLetters (num: number) {
    var letters = ''
    var base = 26
    var digits = Math.floor(Math.log(num) / Math.log(base)) + 1
  
    while (digits > 0) {
      var digitVal = Math.floor(num / Math.pow(base, digits - 1))
      num = num % Math.pow(base, digits - 1)
      letters += String.fromCharCode(65 + digitVal)
      digits--
    }
  
    return letters
  }
  

const strings = { isUrl, convertToLetters }
export default strings