import { Input } from "antd";
import React, { useState, useEffect } from "react";

export const convertTo24HourFormat = (time12Hour) => {
  const match = time12Hour.match(/(\d{1,2}):(\d{2})\s(AM|PM)/i);

  if (!match) {
    // La entrada no coincide con el formato esperado
    return null;
  }

  let [, hours, minutes, period] = match;

  hours = parseInt(hours, 10);
  minutes = parseInt(minutes, 10);

  if (period.toUpperCase() === "PM" && hours !== 12) {
    hours += 12;
  } else if (period.toUpperCase() === "AM" && hours === 12) {
    hours = 0;
  }

  const formattedHour = hours.toString().padStart(2, "0");
  const formattedMinute = minutes.toString().padStart(2, "0");

  return `${formattedHour}:${formattedMinute}`;
};

const TimeSelector = ({ value, onChange, defaultValue = "9:00 am" }) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (event) => {
    const newValue = event.target.value;

    // Validar si la entrada coincide con el formato hh:mm AM/PM
    const regex = /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;
    const isValidFormat = regex.test(newValue);

    setIsValid(isValidFormat);
    setInputValue(newValue);
  };

  useEffect(() => {
    onChange(inputValue);

    return () => {};
    // eslint-disable-next-line
  }, [inputValue]);

  return (
    <div>
      <Input
        prefix={<i className="ri-time-line"></i>}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        suffix={
          <i
            className={isValid ? "ri-check-line" : "ri-close-line"}
            style={{ color: isValid ? "green" : "red" }}
          />
        }
      />
    </div>
  );
};

export default TimeSelector;
