// import SolicitarCita from "pages/ConsultaExterna/SolicitarCita";
import Home from "pages/Home";
import { createHashRouter } from "react-router-dom";

const addLayout = (element) => {
  return element;
};

const generatePrivateRoutes = (routes) => {
  return routes.map((route) => {
    if (route.children) {
      return {
        ...route,
        children: generatePrivateRoutes(route.children),
      };
    }
    return {
      ...route,
      element: addLayout(route.element),
    };
  });
};

const Routesx = createHashRouter([
  ...generatePrivateRoutes([
    {
      path: "/",
      element: <Home />,
    },
  ]),
]);

export default Routesx;
