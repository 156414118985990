import React from "react";
import { Switch } from "antd";
import { GeneralControlProps } from "../../../../types/interfaces";

const SwitchControl = ({ hookForm, rest }: GeneralControlProps) => {
  return (
    <div>
      <Switch
        onChange={(e) => {
          rest.onChange && rest.onChange(e);
          if (hookForm.field) {
            hookForm.field.onChange(e);
          } else {
            hookForm.onChange(e);
          }
        }}
        defaultChecked={rest.defaultValue}
      />
    </div>
  );
};

export default SwitchControl;
