import React from "react";
import { LogoStyled } from "./styled";
import LOGOHSRP from "assets/LOGOHSRP.png";
// import { useUsuario } from "hooks/ContextUser";
import { useNavigate } from "react-router-dom";
import { useMediaQueryCustom } from "core/Hooks/useMediaQueryCustom";
const Logo = ({ color = "white", noref = false }) => {
  // const { userIsLogged } = useUsuario();
  const { isTabletOrMobile } = useMediaQueryCustom();

  const navigate = useNavigate();

  const handleClickLogo = () => {
    !noref && navigate("/");
  };

  return (
    <LogoStyled
      noref={noref}
      responsive={isTabletOrMobile}
      onClick={handleClickLogo}
    >
      <img src={LOGOHSRP} alt="..." />
      {!isTabletOrMobile && (
        <h3 style={{ color }}>
          Hospital de la Amistad Perú Corea Santa Rosa II-2
        </h3>
      )}
    </LogoStyled>
  );
};

export default Logo;
