import { Formulario } from "core/ts_formulario_model";
import { generateMapForm } from "core/utils/tables";
import React from "react";

const SeguimientoReclamo = ({ onCancel }) => {
  return (
    <div>
      <Formulario
        width="90%"
        onSubmit={(data) => {
          const id = data.CodigoReclamacion;
          const url = `http://190.223.56.109:3000/api/libroReclamaciones/reclamo/visor/${data.NroDocumento}/${id}`;
          window.open(url, "_blank");
        }}
      >
        <Formulario.FormControls
          onCancel={onCancel}
          submitLabel="Consultar reclamo"
          gap="10px"
          controlGap={"5px"}
          cols={1}
          inputs={generateMapForm([
            {
              name: "NroDocumento",
              type: "input",
              required: true,
              customProps: {
                type: "number",
                required: true,
              },
            },
            {
              name: "CodigoReclamacion",
              type: "input",
              required: true,
              customProps: {
                type: "number",
                required: true,
              },
            },
          ])}
        ></Formulario.FormControls>
      </Formulario>
    </div>
  );
};

export default SeguimientoReclamo;
