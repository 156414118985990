import React from "react";
import { Button, Divider } from "antd";
import FormControl from "../../Molecules/FormControl";
import { Column, Heading } from "core/util-styled-components";
// import { FormProvider, useForm } from "react-hook-form";
import { IInputPayload } from "../../../../types/interfaces";
import { GridChild } from "core/util-styled-components/components/Grid";
import SelectorDisplays from "core/util-styled-components/components/SelectorDisplays";

const FormControls = ({ children, gap, controlGap, ...restProps }: any) => {
  return (
    <Column gap={gap || "10px"}>
      {restProps.inputs && <Controls gap={controlGap || "30px"} inputs={restProps.inputs} cols={restProps.cols} type={restProps.type} />}
      {children}
      <Column width={"100%"} style={{ alignSelf: "center" }} gap={gap || "5px"}>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          {restProps.submitLabel || "Guardar"}
        </Button>
        <Button type="default" onClick={restProps.onCancel ? restProps.onCancel : () => {}}>
          {restProps.cancelLabel || "Cancelar"}
        </Button>
      </Column>
    </Column>
  );
};

FormControls.Extra = ({ children }: any) => <div>{children}</div>;

const Controls = ({ inputs, cols, type = "grid", gap }: any) => (
  <SelectorDisplays type={type} gap={gap || "30px"} cols={cols}>
    {inputs
      .filter((_) => !_.disabled)
      .map((el: IInputPayload, key: number) => {
        if (el.type === "title-separator") {
          return (
            <GridChild
              span={cols}
              spanCol={el.customProps ? el.customProps.spanCol : undefined}
              spanRow={el.customProps ? el.customProps.spanRow : undefined}
              style={
                el.customProps?.disabledButRender
                  ? {
                      display: "none",
                    }
                  : {}
              }
            >
              <Divider orientation="left" orientationMargin={"0em"} style={el.customProps?.style}>
                <Heading variant="h3">{el.name}</Heading>
              </Divider>
            </GridChild>
          );
        }
        if (el.type === "custom") {
          return (
            <GridChild
              span={cols}
              spanCol={el.customProps ? el.customProps.spanCol : undefined}
              spanRow={el.customProps ? el.customProps.spanRow : undefined}
              style={
                el.customProps?.disabledButRender
                  ? {
                      display: "none",
                    }
                  : {}
              }
            >
              <Column gap="10px">
                {el.name && (
                  <Heading variant="h4" style={el.customProps?.style}>
                    {el.name}
                  </Heading>
                )}
                {el.customProps?.render}
              </Column>
            </GridChild>
          );
        }
        if (el.fullWidth) {
          return (
            <GridChild
              span={cols}
              spanCol={el.customProps ? el.customProps.spanCol : undefined}
              spanRow={el.customProps ? el.customProps.spanRow : undefined}
              style={
                el.customProps?.disabledButRender
                  ? {
                      display: "none",
                    }
                  : {}
              }
            >
              <FormControl fluid={type !== "grid"} key={key} {...el} orientation={"column"} />
            </GridChild>
          );
        }
        return (
          <GridChild
            span={el.fullWidth ? cols : 1}
            spanCol={el.customProps ? el.customProps.spanCol : undefined}
            style={
              el.customProps?.disabledButRender
                ? {
                    display: "none",
                  }
                : {}
            }
          >
            <FormControl fluid={type !== "grid"} key={key} {...el} orientation={"column"} />
          </GridChild>
        );
      })}
  </SelectorDisplays>
);

FormControls.Controls = Controls;

export default FormControls;
