// import { DatePicker } from "antd";
import React from "react";
import { GeneralControlProps } from "../../../../types/interfaces";
import { DatePickerStyled, TimePickerStyled } from "../FormControl/styled";

const CalendarControl = ({ hookForm, rest }: GeneralControlProps) => {
  return (
    <div>
      {rest.customProps?.type === "time" && (
        <TimePickerStyled
          onChange={(e) => {
            rest.onChange && rest.onChange(e);
            if (hookForm.field) {
              hookForm.field.onChange(e);
            } else {
              hookForm.onChange(e);
            }
          }}
          defaultValue={rest.defaultValue}
          {...rest.customProps}
        />
      )}

      {rest.customProps?.type !== "time" && (
        <DatePickerStyled
          onChange={(e) => {
            rest.onChange && rest.onChange(e);
            if (hookForm.field) {
              hookForm.field.onChange(e);
            } else {
              hookForm.onChange(e);
            }
          }}
          defaultValue={rest.defaultValue}
          {...rest.customProps}
        />
      )}
    </div>
  );
};

export default CalendarControl;
