import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "remixicon/fonts/remixicon.css";
import { Theme } from "core/Theme/theme";
import { ThemeProvider } from "styled-components";
import { UsuarioProvider } from "core/Hooks/global/UsuarioContext";
import { GlobalStyled } from "core/Theme/GlobalStyled";
import { AntConfig } from "core/Theme/antconfig";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <UsuarioProvider>
        <GlobalStyled>
          <ConfigProvider theme={AntConfig}>
            <App />
          </ConfigProvider>
        </GlobalStyled>
      </UsuarioProvider>
    </ThemeProvider>
  </React.StrictMode>
);
