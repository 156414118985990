import { useEffect } from "react";
import { useState } from "react";
import Axios from "axios";
import AxiosTokenProvider from "axios-token-interceptor";
import { useUsuario } from "./global/UsuarioContext";
import { HTTPS_HOST_API, PARAMS_HOST } from "../Api/API";
import { axiosNotification } from "core/utils/notificaciones";
function useAxios(
  metodo,
  url,
  noEjectuarAlInicio = false,
  parametros = {},
  axiosLoader = false,
  _host_api = HTTPS_HOST_API
) {
  const { usuario } = useUsuario();
  const [respuestaServer, setRespuestaServer] = useState(null);
  const [datos, setDatos] = useState([]);
  const [error, setError] = useState(null);
  const [cargando, setCargando] = useState(false);
  const resetearConsulta = () => {
    setRespuestaServer(null);
    setDatos([]);
    setError(null);
    setCargando(false);
  };
  const actualizarParametros = (nuevosParametros) => {
    parametros = nuevosParametros;
  };
  const actualizarUrl = (nuevaUrl) => {
    url = nuevaUrl;
  };
  const crearInstanciaAxios = () => {
    const instance = Axios.create({
      // baseURL: "http://192.168.1.159:5000",
      baseURL: _host_api,
      // baseURL: "http://localhost:5000",
    });
    usuario &&
      instance.interceptors.request.use(
        AxiosTokenProvider({
          getToken: () => usuario.store + " " + usuario.rol,
        })
      );
    return instance;
  };
  const enviarPeticion = async (instance) => {
    let respuesta = null;
    respuesta = await instance.post(PARAMS_HOST, {
      method: metodo,
      route: url,
      params: parametros,
    });
    return respuesta;
  };
  const iniciarPeticion = async () => {
    resetearConsulta();
    try {
      return await recargarPeticion();
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    axiosLoader && axiosNotification(cargando, respuestaServer);
    return () => {};
  }, [axiosLoader, cargando, respuestaServer]);

  const recargarPeticion = async () => {
    setCargando(true);
    try {
      const instanciaAxios = crearInstanciaAxios();
      const respuesta = await enviarPeticion(instanciaAxios);
      setRespuestaServer(200);
      respuesta && setDatos(respuesta.data);
      setCargando(false);
      return respuesta.data;
    } catch (error) {
      setRespuestaServer(400);
      setDatos([]);
      setCargando(false);
      setError(error);
      throw new Error("Error en el servicio de datos");
    }
  };

  useEffect(() => {
    if (!noEjectuarAlInicio) {
      iniciarPeticion();
    }
    return () => {};
    //eslint-disable-next-line
  }, [usuario, noEjectuarAlInicio]);
  return {
    datos,
    cargando,
    error,
    setDatos,
    actualizarParametros,
    iniciarPeticion,
    recargarPeticion,
    actualizarUrl,
    resetearConsulta,
    respuestaServer,
  };
}

export const ValidateAxiosData = (datos) =>
  Array.isArray(datos) && datos.length > 0;

export const ValidateAxiosDataWithData = (datos) =>
  !Array.isArray(datos) && Array.isArray(datos.data) && datos.data.length > 0;

export default useAxios;
