import React from "react";
import { Input, InputNumber } from "antd";
import { GeneralControlProps } from "../../../../types/interfaces";
import { Row } from "core/util-styled-components";
import str from "core/util-functions/src/core/strings";
import TimeSelector from "./TimeControl";

const InputControl = ({ rest, hookForm }: GeneralControlProps) => {
  if (rest.customProps && rest.customProps.type === "file") {
    return (
      <Row gap="10px">
        <input
          type="file"
          id={rest.name}
          onChange={(e) => {
            if (hookForm.field) {
              hookForm.field.onChange(e.target.files);
            } else {
              hookForm.onChange(e.target.files);
            }
          }}
          defaultValue={str.isUrl(rest.defaultValue) ? "" : rest.defaultValue}
          {...rest.customProps}
        />
        {rest.defaultValue && str.isUrl(rest.defaultValue) && (
          <a href={rest.defaultValue} target={"_blank"} rel="noreferrer">
            Ver PDF Cargado
          </a>
        )}
      </Row>
    );
  }

  if (rest.customProps && rest.customProps.type === "time-x") {
    return (
      <TimeSelector
        onChange={(e) => {
          rest.onChange && rest.onChange(e);
          if (hookForm.field) {
            hookForm.field.onChange(e);
          } else {
            hookForm.onChange(e);
          }
        }}
        defaultValue={rest.defaultValue}
        {...rest.customProps}
      />
    );
  }

  return (
    <Input
      onChange={(e) => {
        rest.onChange && rest.onChange(e);
        if (hookForm.field) {
          hookForm.field.onChange(e);
        } else {
          hookForm.onChange(e);
        }
      }}
      defaultValue={rest.defaultValue}
      {...rest.customProps}
    />
  );
};

export default InputControl;
