import styled from "styled-components";
import { IOverrideDiv } from "../types/Interfaces";

export const Button = styled.button`
  cursor: pointer;
  :focus {
    outline: none;
  }
`;
export const Div = styled.div<IOverrideDiv>`
  width: ${(props: IOverrideDiv) => (props.width ? props.width : "auto")};
  padding: ${(props: IOverrideDiv) => (props.padding ? props.padding : "0")};
  margin: ${(props: IOverrideDiv) => (props.margin ? props.margin : "0")};
  border: ${(props: IOverrideDiv) => (props.border ? props.border : "0")};
  @media (max-width: 768px) {
    width: ${(props) => (props.responsive ? "100%" : props.width)};
  }
`;
