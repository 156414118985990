import React, { useState, useEffect, useMemo } from "react";
import Axios from "axios";
import AxiosTokenProvider from "axios-token-interceptor";
// import useLocalStorage from "../useLocalStorage";
import { HOST_API } from "../../Api/API";
import { Row, Spin } from "antd";

const UsuarioContext = React.createContext();
export function UsuarioProvider(props) {
  const [usuario, setUsuario] = useState(null);
  const [cargandoUsuario, setCargandoUsuario] = useState(true);
  // const [setLogin, getLogin] = useLocalStorage("login");

  useEffect(() => {
    recuperarUsuario();
    return () => {};
    //eslint-disable-next-line
  }, []);
  const recuperarUsuario = async () => {
    setCargandoUsuario(true);
    // const store = getLogin();
    // if (store && store.login) {
    // this user is limited for usuario-reclamaciones
    setUsuario({
      store:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTU5MTgzNDN9.IxqMSar-kPSuALjc4XSuVod3gP7s-9Zgjc-sBhM80as",
      rol: 39,
    });
    // }

    setCargandoUsuario(false);
  };

  const logout = () => {
    localStorage.removeItem("login");
    sessionStorage.clear();
    setUsuario(null);
  };
  const uploadFile = async (url, selectedFile) => {
    if (usuario !== null) {
      if (usuario.store !== null) {
        if (selectedFile === "undefined" || selectedFile === null) {
          console.log("no cargo nada");
          return false;
        } else {
          try {
            const instance = Axios.create({
              baseURL: HOST_API,
            });
            instance.interceptors.request.use(
              AxiosTokenProvider({
                getToken: () => usuario.store + " " + usuario.rol,
              })
            );
            let formData = new FormData();
            formData.append("file", selectedFile, selectedFile.name);
            formData.append("name", "file");
            formData.append("id", "file");
            formData.append("file", "file");
            const res = await instance.post(url, formData);
            let data = res.data;
            return data;
          } catch (error) {
            return false;
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const value = useMemo(() => {
    return {
      usuario,
      cargandoUsuario,
      logout,
      uploadFile,
    };
    //eslint-disable-next-line
  }, [usuario, cargandoUsuario]);

  if (!cargandoUsuario) {
    return <UsuarioContext.Provider value={value} {...props} />;
  }
  return (
    <Row>
      <Spin />
      <span>Cargando usuario...</span>
    </Row>
  );
}
export function useUsuario() {
  const context = React.useContext(UsuarioContext);
  if (!context) {
    throw new Error("No se encontro Usuario en el contexto");
  }
  return context;
}
