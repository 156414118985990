export const generateMapTable = (jsonArray) => {
  if (!Array.isArray(jsonArray)) {
    return [];
  }
  return jsonArray.map((el) => tableColumn(el));
};

export const generateMapForm = (jsonArray, defaultValues = {}) => {
  if (!Array.isArray(jsonArray)) {
    return [];
  }
  return jsonArray.map((el) => formColumn(el, defaultValues[el]));
};

//If add a json object to the array, "dataIndex" is required
const tableColumn = (column) => {
  if (typeof column === "object") {
    return {
      ...column,
      key: column.key ? column.key : column.dataIndex,
      title: column.title ? column.title : column.dataIndex,
      dataIndex: column.dataIndex ? column.dataIndex : column.key,
    };
  }

  return {
    title: column,
    key: column,
    dataIndex: column,
  };
};

const formColumn = (control, defaultValue = "") => {
  if (typeof control === "object") {
    return {
      ...control,
      defaultValue,
      customProps: {
        defaultValue,
        ...control.customProps,
      },
    };
  }

  return {
    name: control,
    type: "input",
    defaultValue,
    customProps: {
      defaultValue,
      ...control.customProps,
    },
  };
};
