import styled from "styled-components";
import { IOverrideDiv } from "../types/Interfaces";

// const recalculate = (width) => {
//     let extension = width ? width.substr(width.length-1,width.length) : "$";
//     if(extension === "%"){
//         return width.substr(0,width.length-1) > 50 ? "100%" : "50%"
//     }else{
//         return width
//     }
// }

export const Button = styled.button`
  cursor: pointer;
  :focus {
    outline: none;
  }
`;
export const Div = styled.div<IOverrideDiv>`
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "auto")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  border: ${(props) => (props.border ? props.border : "0")};
  ${(props) => {
    return (
      props.addShadow &&
      `box-shadow: 0px 2px 16px 3px rgba(85,109,235,0.29);
      -webkit-box-shadow: 0px 2px 16px 3px rgba(85,109,235,0.29);
      -moz-box-shadow: 0px 2px 16px 3px rgba(85,109,235,0.29);`
    );
  }}

  @media (max-width: 768px) {
    width: ${(props) => (props.responsive ? "100%" : props.width)};
  }
`;

export const ResizeTypography = styled.div<{ fontSize: string }>`
  * {
    font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  }
`;
