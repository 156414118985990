import { Modal, message } from "antd";
import { Container } from "app/components/Container";
import Logo from "app/components/Logo";
import useAxios from "core/Hooks/useAxios";
import { useMediaQueryCustom } from "core/Hooks/useMediaQueryCustom";
import { Formulario } from "core/ts_formulario_model";
import { Column, Row, Typography } from "core/util-styled-components";
import { generateMapTable } from "core/utils/tables";
import React, { useState } from "react";
import SeguimientoReclamo from "./SeguimientoReclamo";

const Home = () => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [reload, setReload] = useState(false);
  const InsertarReclamo = useAxios("POST", "/api/libroReclamaciones/insertar", true);
  const [seguimientoModal, setSeguimientoModal] = useState(false);
  async function esperarDiezSegundosAsync() {
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Espera 1 segundos de manera asincrónica
    console.log("Han pasado 1 segundos");
  }

  const onReload = async () => {
    setReload(true);
    await esperarDiezSegundosAsync();
    setReload(false);
  };

  const submit = async (data) => {
    try {
      const params = {
        fecha: new Date().toISOString().split("T")[0],
        ...data,
        TipoBienContratado: 0,
        TipoReclamacion: 0,
      };
      InsertarReclamo.actualizarParametros(params);
      const response = await InsertarReclamo.iniciarPeticion();
      if (response.length) {
        const id = response[0].codigo;
        const url = `http://190.223.56.109:3000/api/libroReclamaciones/reclamo/visor/${data._Documento}/${id}`;

        message.success("Reclamo generado correctamente.");
        Modal.confirm({
          title: "Reclamo generado",
          content: "Se ha generado un reclamo correctamente.",
          onOk: () => {
            onReload();
            window.open(url, "_blank");
          },
          onCancel: onReload,
        });
      } else {
        message.error("No se ha podido generar el reclamo,revisa bien su formulario");
      }
    } catch (error) {
      message.error("No se ha podido generar el reclamo,revisa bien su formulario");
    }
  };

  return (
    <Container width={isTabletOrMobile ? "100%" : "60%"} style={{ margin: "auto" }}>
      <Row gap="10px">
        <Logo color="black" />
        <span
          style={{
            height: "40px",
            border: "solid 1px #dfdfdf",
          }}
        />
        <Column alignItems="flex-start">
          <Typography variant={isTabletOrMobile ? "h3" : "h2"} style={{ fontWeight: "800", textTransform: "uppercase" }}>
            Libro de reclamaciones
          </Typography>
          <Typography variant="label">No dude en informar si es victima de un abuso o engaño</Typography>
        </Column>
      </Row>
      {reload ? (
        <Typography>Generando nuevo formulario...</Typography>
      ) : (
        <Formulario onSubmit={submit}>
          <Formulario.FormControls
            gap="10px"
            controlGap="10px 5px"
            cols={12}
            submitLabel="Enviar mi reclamo"
            cancelLabel="Seguimiento de mi reclamo"
            onCancel={() => {
              setSeguimientoModal(true);
            }}
            inputs={generateMapTable([
              {
                name: <Typography variant={isTabletOrMobile ? "h4" : "h3"}>1. Identificación del Usuario o tercero legitimado</Typography>,
                type: "title-separator",
                customProps: {
                  spanCol: "span 12",
                },
              },
              {
                name: "_TipoDocumento",
                type: "radio-button",
                optionsDropdown: [
                  { name: "DNI", value: 1 },
                  { name: "Doc.Extranjero", value: 2 },
                  { name: "Pasaporte", value: 3 },
                  { name: "RUC", value: 4 },
                ],
                required: true,
                optionLabel: "name",
                optionValue: "value",
                customProps: {
                  required: true,
                  spanCol: `span ${isTabletOrMobile ? 12 : 3}`,
                  displayName: "Tipo de documento",
                },
              },
              {
                name: "_Documento",
                type: "input",
                required: true,
                customProps: {
                  type: "number",
                  required: true,
                  spanCol: `span ${isTabletOrMobile ? 12 : 3}`,
                  displayName: "Num. Documento",
                  placeholder: "Ejm: 12345678",
                },
              },
              {
                name: "_Nombres",
                type: "input",
                required: true,
                customProps: {
                  required: true,
                  spanCol: `span ${isTabletOrMobile ? 12 : 6}`,
                  displayName: "Nombre completo",
                  placeholder: "Ejm: John Doe",
                },
              },
              {
                name: "_correo",
                type: "input",
                required: true,
                customProps: {
                  required: true,
                  type: "email",
                  spanCol: `span ${isTabletOrMobile ? 12 : 3}`,
                  displayName: "Correo electronico",
                  placeholder: "example@gmail.com",
                },
              },
              {
                name: "_Telefono",
                type: "input",
                required: true,
                customProps: {
                  required: true,
                  type: "number",
                  placeholder: "987654321",
                  spanCol: `span ${isTabletOrMobile ? 12 : 3}`,
                },
              },
              {
                name: "_Domicilio",
                type: "input",
                customProps: {
                  spanCol: `span ${isTabletOrMobile ? 12 : 6}`,
                  placeholder: "Calle ... Av ... Jr...#123",
                },
              },
              {
                name: (
                  <Column>
                    <Typography variant={isTabletOrMobile ? "h4" : "h3"}>
                      2. Identificación de quien presenta el reclamo {isTabletOrMobile ? "" : <Typography variant="label">(En caso de ser el usuario afectado no es necesario su llenado)</Typography>}
                    </Typography>
                    {!isTabletOrMobile ? "" : <Typography variant="label">(En caso de ser el usuario afectado no es necesario su llenado)</Typography>}
                  </Column>
                ),
                type: "title-separator",
                customProps: {
                  spanCol: "span 12",
                },
              },
              {
                name: "TipoDocumento",
                type: "radio-button",
                optionsDropdown: [
                  { name: "DNI", value: 1 },
                  { name: "Doc.Extranjero", value: 2 },
                  { name: "Pasaporte", value: 3 },
                  { name: "RUC", value: 4 },
                ],
                optionLabel: "name",
                optionValue: "value",
                customProps: {
                  spanCol: `span ${isTabletOrMobile ? 12 : 3}`,
                  displayName: "Tipo de documento",
                },
              },
              {
                name: "Documento",
                type: "input",
                customProps: {
                  type: "number",
                  spanCol: `span ${isTabletOrMobile ? 12 : 3}`,
                  displayName: "Num. Documento",
                  placeholder: "Ejm: 12345678",
                },
              },
              {
                name: "Nombres",
                type: "input",
                customProps: {
                  spanCol: `span ${isTabletOrMobile ? 12 : 6}`,
                  displayName: "Nombre completo",
                  placeholder: "Ejm: John Doe",
                },
              },
              {
                name: "correo",
                type: "input",
                customProps: {
                  type: "email",
                  spanCol: `span ${isTabletOrMobile ? 12 : 3}`,
                  displayName: "Correo electronico",
                  placeholder: "example@gmail.com",
                },
              },
              {
                name: "Telefono",
                type: "input",
                customProps: {
                  type: "number",
                  placeholder: "987654321",
                  spanCol: `span ${isTabletOrMobile ? 12 : 3}`,
                },
              },
              {
                name: "Domicilio",
                type: "input",
                customProps: {
                  spanCol: `span ${isTabletOrMobile ? 12 : 6}`,
                  placeholder: "Calle ... Av ... Jr...#123",
                },
              },

              {
                name: "3. Información del reclamo",
                type: "title-separator",
                customProps: {
                  spanCol: "span 12",
                },
              },
              {
                name: "detalleReclamo",
                type: "input-description",
                required: true,
                customProps: {
                  displayName: "Detalle del reclamo",
                  spanCol: `span 12`,
                  required: true,
                  style: { minHeight: "150px" },
                  placeholder: "Describa el detalle del reclamo...",
                },
              },
            ])}
          >
            <Column>
              <Typography>* El plazo se deben atender en un plazo de 30 días habiles.</Typography>
              <Typography>* Al enviar este reclamo acepta que se le envie la respuesta a su correo electronico o que lo llamen al numero de telefono brindado.</Typography>
            </Column>
          </Formulario.FormControls>
        </Formulario>
      )}
      <Modal open={seguimientoModal} onCancel={() => setSeguimientoModal(false)} footer={null} title="Seguimiento de reclamaciones">
        <SeguimientoReclamo onCancel={() => setSeguimientoModal(false)} />
      </Modal>
    </Container>
  );
};

export default Home;
