const HTTP = "http";
const BASE_URL = "190.223.56.109";
// const BASE_URL = "localhost";
const PORT_API_REAL_TIME = "5001";
const PORT_API = "5000";

const HOST_API_REAL_TIME = `${HTTP}://${BASE_URL}:${PORT_API_REAL_TIME}/`;
const HOST_API = `${HTTP}://${BASE_URL}:${PORT_API}`;
export {
  HTTP,
  BASE_URL,
  PORT_API_REAL_TIME,
  PORT_API,
  HOST_API_REAL_TIME,
  HOST_API,
};

export const HTTPS_HOST_API = "https://elzeein.com:5001";
export const PARAMS_HOST = "/api/v1/consumer";

export const LimitedToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTU5MTgzNDN9.IxqMSar-kPSuALjc4XSuVod3gP7s-9Zgjc-sBhM80as";
export const LimitedRol = 39;
